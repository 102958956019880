import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './config/Router';
import { defaultLocale, languages } from './languages/index';
import VueScrollmagic from 'vue-scrollmagic'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.config.productionTip = false;
Vue.use(VueScrollmagic)
Vue.use(VueI18n)
Vue.use(VueAwesomeSwiper)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: Object.assign(languages)
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
